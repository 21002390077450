import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const {
  ROBOT
} = publicRuntimeConfig;

const isRobot = (req = {}) => {
  const { headers } = req;
  if (headers) {
    console.log('headers', headers);
    console.log('req.url', req.url);
    const val = headers['user-agent'];
    const botTest = new RegExp(ROBOT, 'i');
    return { isSEO: botTest.test(val) };
  }
  return {};
};

export default isRobot;
