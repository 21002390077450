const bankCard = {
  getCurrentDate: () => new Date(),
  isExpired: (expires: String) => {
    const today = bankCard.getCurrentDate();
    const year = `20${expires.split('/')[1]}`;
    const month = expires.split('/')[0];
    const correctExpiresDate = new Date(+year, +month, 0, 23, 59, 59, 999)
    console.debug(`Checking ${expires} card with markup ${correctExpiresDate}, today is ${today}`);
    if (correctExpiresDate < today) {
      return true;
    } else {
      return false;
    }
  },
};

export default bankCard;
