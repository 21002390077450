import React,
{
  FunctionComponent, useContext,
  useEffect, useState,
} from 'react';
import {useApolloClient, useLazyQuery,} from '@apollo/react-hooks';
import Layout from '../../layout';
import GET_BANK_CARDS from './GET_BANK_CARDS.graphql';
import { Context } from '../../../helpers/context';
import bankCard from '../../../helpers/bankCard';
import { Confirmation, CloseConfirm } from '../../layout/layout.style';
import UPDATE_SOURCE_USER from './UPDATE_SOURCE_USER.graphql';
import getApolloClient from '../../../helpers/getApollo';
import { PageLoader } from '../../ui/loading';
import { useRouter } from 'next/router';
const desWebsite = 'WeGive Ventures is a social enterprise that aims to maximise charitable giving. No matter how large or small your donation, our app ensures that donations can be made easily to those charities who need it the most.';
export const FORM_DIRECT_URL = 'FORM_DIRECT_URL';
interface ILayoutProps {
  children: React.ReactNode,
  title?: string,
  header?: string,
  showBack?: boolean,
  showFooter?: boolean,
  logoURL?: string,
  description?: string,
  showHeader?: boolean,
  primaryBackground?: boolean,
  image?: string
}

const LayoutProvider:FunctionComponent<ILayoutProps> = ({
  children,
  title = '',
  header = '',
  logoURL = '/logo/logo-192.png',
  description = desWebsite,
  showHeader = true,
  showBack = false,
  showFooter = true,
  primaryBackground = false,
  image,
}: any) => {
  const route = useRouter();
  const { asPath } = route;
  const {
    context: { auth }, isSignup, contextSignupSuccess, getSource,
    setSource
  } = useContext(Context);
  const [state, setstate] = useState({
    bankCards: [],
    isCardExpired: false,
    isSignupSuccess: false,
    isUpdating: false,
  });
  const [signupSuccess,setIsSignupSuccess]=useState(false)

  const updateState = (value: any) => {
    setstate((prevState: any) => ({ ...prevState, ...value }));
  };

  const findExpiredCard = (bankCards: any) => {
    if (bankCards.length > 0) {
      bankCards.forEach((element :any) => {
        const isCardExpired = bankCard.isExpired(element.expires);
        updateState({ isCardExpired });
      });
    } else {
      updateState({ isCardExpired: false });
    }
  };

  const { isValidUser = false } = auth || {};
  const apolloClient = useApolloClient();
  const bankCardsData = apolloClient.readQuery({
    query: GET_BANK_CARDS,
    variables: { limit: 100, skip: !isValidUser },
  });
  const { items } = bankCardsData?.bankCards || {};

  const [getBankCards, {data}] = useLazyQuery(GET_BANK_CARDS, {
    variables: {
      limit: 100,
      skip: !isValidUser,
    },
    fetchPolicy: 'cache-and-network', 
  });

  apolloClient.writeQuery({
    query: GET_BANK_CARDS,
    data: {
      bankCards: {
        ...data?.bankCards,
        items: items || [], 
        nextToken: null,
      },
    },
  });
  const onClickCloseConfirm = () => {
    setIsSignupSuccess(false)
    updateState({ isSignupSuccess: false });
    contextSignupSuccess();
  };

  const autoHideConfirm = () => {
    setTimeout(() => {
      onClickCloseConfirm();
    }, 10000);
  };
  useEffect(() => {
    if(items && items.length==0){
      getBankCards();
    }
    const { bankCards } = data || bankCardsData || {};
    if (bankCards && isValidUser) {
      setIsSignupSuccess(isSignup)
      const { items } = bankCards;
      updateState({ bankCards: items, isSignupSuccess: isSignup });
      findExpiredCard(items);
      autoHideConfirm();
    }
  }, [data,items,isSignup]);
  const updateSourceFunc = async (source: string, queAsPath: string) => {
    updateState({ isUpdating: true });
    const client = await getApolloClient();
    try {
      const result = await client.mutate({
        mutation: UPDATE_SOURCE_USER,
        variables: {
          source: `${source}${queAsPath}`,
        },
      });
      console.log("result on layout Page:",result)
      if (result) {
        updateState({ isUpdating: false,isSignupSuccess:isSignup });
        setSource(!!result.data);
      }
    } catch (e) {
      console.log(e)
      updateState({ isUpdating: false });
    }
   
  }
  // const checkFirstVisit = () => {
  //   if(document.cookie.indexOf('refreshCokkie')==-1) {
  //     // cookie doesn't exist, create it now
  //     document.cookie = 'refreshCokkie=1';
  //   }
  //   else {
  //     // not first visit, so alert
  //     console.log('You refreshed!');
  //   }
  // }

  const updateSourceWithSlug = async () => {
    const { source, isUpdate } = getSource();
    if (!isUpdate && source !== '') {
      updateSourceFunc(source, asPath.length > 0 ? `:${asPath}` : '');
    } else {
      console.log(source, 'is updated');
    }
  }
  
  const getDirectUrlFromShare = async () => {
    const fromDirectUrl = await localStorage.getItem(FORM_DIRECT_URL);

    if (fromDirectUrl) return;
    if (typeof window !== 'undefined' || typeof window !== undefined) {
      const currentUrl = window.location.href;
      const toSuccess = currentUrl.indexOf('donate/success') !== -1
      if (toSuccess) {
        const objData: any = {};
        const fullQuery = asPath.split('/donate/success?').join('').split('&');
        fullQuery.map((item: any) => {
          const itemSeperate = item.split('=');
          objData[itemSeperate[0]] = itemSeperate[1];

        });

        await localStorage.setItem(FORM_DIRECT_URL, JSON.stringify(objData));
      }
    }
  }


  useEffect(() => {
    updateSourceWithSlug();
    getDirectUrlFromShare();
  }, []);
  const { isCardExpired, isSignupSuccess, isUpdating } = state;
  return (
    <Layout
      title={title}
      header={header}
      showBack={showBack}
      showFooter={showFooter}
      notify={isCardExpired}
      logoURL={logoURL}
      description={description}
      showHeader={showHeader}
      primaryBackground={primaryBackground}
      image={image}
      auth={auth}
    >
      {isUpdating && <PageLoader/>}
      {signupSuccess && (
        <Confirmation>
          <CloseConfirm data-cy="signup-close-confirm" onClick={onClickCloseConfirm} />
          You have successfully signed up to your new account
        </Confirmation>
      )}
      {children}
    </Layout>
  );
};

export default LayoutProvider;
